import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spinner, Input } from '@cfacorp/cowponents';
import { Button } from '@cfa/react-components';
import { OverlayModal, Icon, Disclaimer } from '../index';
import { exactFavoriteMatches } from '../../util/validate';

export const FavoriteOrder = props => {
  const {
    isFavorite,
    setFavoriteName,
    guestName,
    suggestedFavoriteName,
    favoriteActionSuccessful,
    close,
    currentFavoriteNames,
    removeFromFavorites,
    addToFavorites,
    favoritesLoading,
    favoritesError,
    updateFavoriteName,
    isOpen,
  } = props;

  const [favoriteName, setStateFavoriteName] = useState('');
  const [favoriteNameInvalid, setFavoriteNameInvalid] = useState(false);

  useEffect(() => {
    if (isFavorite) setStateFavoriteName(setFavoriteName);
    else setStateFavoriteName(suggestedFavoriteName || `${guestName}'s Catering Order`);
  }, []);

  const closeModal = () => close({ favoriteActionSuccessful });

  useEffect(() => {
    if (favoriteActionSuccessful) setTimeout(closeModal, 1500);
  }, [favoriteActionSuccessful]);

  const handleChange = e => {
    setStateFavoriteName(e.target.value);
  };

  const checkForMatch = e => {
    const nameToCheck = e.target.value;
    const matchFound = exactFavoriteMatches(currentFavoriteNames, nameToCheck).length;
    if (matchFound) setFavoriteNameInvalid(true);
    else setFavoriteNameInvalid(false);
  };

  const renderActions = () => {
    if (favoritesLoading) {
      return <Spinner />;
    }
    if (favoritesError || favoriteActionSuccessful) {
      return (
        <IconMessage>
          <Icon width="20px" height="20px" margin="5px">{favoritesError ? 'error' : 'success'}</Icon>
          {favoritesError
            ? 'Uh oh! There was a problem performing this action. Please close this window and try again or contact support.'
            : 'Success!'}
        </IconMessage>
      );
    }
    return (
      <>
        { favoriteNameInvalid && (
          <WarningMessage className="warning-message">
            <Icon width="15px" height="15px" margin="0 4px 2px">warning</Icon>
            Each favorite name must be a unique. Please try a different name.
          </WarningMessage>
        )}
        {isFavorite
          ? (
            <div>
              <Button variant="destructive" className="remove-favorite" size="md" onClick={removeFromFavorites}>Remove from Favorites</Button>
              <Button
                className="update-favorite"
                color="secondary"
                size="md"
                onClick={() => updateFavoriteName(favoriteName)}
                disabled={favoriteNameInvalid}
              >
                Update Favorite Name
              </Button>
            </div>
          ) : (
            <div>

              <Button
                className="save-favorite"
                color="secondary"
                size="md"
                onClick={() => addToFavorites(favoriteName)}
                disabled={favoriteNameInvalid}
              >
                Save to Favorites
              </Button>
              <Button variant="text" size="md" color="secondary" onClick={closeModal}>Cancel</Button>
            </div>
          )}
      </>
    );
  };

  return (
    <StyledFavoriteOrder close={closeModal} isOpen={isOpen}>
      <Content>
        <FavoriteIcon>favorite</FavoriteIcon>
        <Header>{`${isFavorite ? 'Update Favorite Order' : 'Add to Favorites'}`}</Header>
        <Message>
          {isFavorite
            ? 'Update the name for this Favorite Order or remove it from Favorites. This change will be visible to the guest under \'View Account\' on Chick-fil-A.com.'
            : 'This order will be saved to Favorite Orders and will be accessible by the guest under \'View Account\' on Chick-fil-A.com.'}
        </Message>
        <FavoriteName
          className="favorite-input"
          autoFocus
          value={favoriteName}
          onChange={handleChange}
          onKeyUp={checkForMatch}
          data-testid="favorite-input"
        />
        <ButtonWrapper>
          {renderActions()}
        </ButtonWrapper>
        <Close data-testid="close-modal" className="close-favorite" onClick={closeModal}>
          <Icon>close</Icon>
        </Close>
      </Content>
    </StyledFavoriteOrder>
  );
};

export const StyledFavoriteOrder = styled(OverlayModal)`
  text-align: center;
`;
const Content = styled('div')`
  padding: 20px;
`;
const Header = styled('h2')`
  margin-top: 0;
`;
const Message = styled('div')`
  line-height: 25px;
  margin: 20px auto; 
  max-width: 60%;
  @media (max-width: ${(props) => props.theme.phone}) {
    max-width: 90%;
  }
`;
const FavoriteName = styled(Input)`
  width: 60%;
  margin:  0 auto 20px;
  @media (max-width: ${(props) => props.theme.phone}) {
    width: 90%;
  }
`;
const FavoriteIcon = styled(Icon)`
  height: 25px;
  width: 20px;
  fill: ${(props) => props.theme.disabled};
`;
const ButtonWrapper = styled('div')`
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & button {
    margin: 4px;
  }

  @media (max-width: ${(props) => props.theme.phone}) {
    height: auto;
  }
`;
const IconMessage = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 55%;
`;
const WarningMessage = styled(Disclaimer)`
  display: flex;
  align-items: center;
  @media (max-width: ${(props) => props.theme.phone}) {
    margin: 10px;
    flex-direction: column;
  }
`;
const Close = styled('div')`
  position: absolute;
  top: 0;
  right: 5px;
  & svg {
    margin: 0;
    width: 20px;
    fill: ${(props) => props.theme.outline};
  }
  :hover {
    cursor: pointer;
  }
`;

FavoriteOrder.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isFavorite: PropTypes.bool,
  addToFavorites: PropTypes.func.isRequired,
  removeFromFavorites: PropTypes.func,
  updateFavoriteName: PropTypes.func,
  favoritesLoading: PropTypes.bool.isRequired,
  favoriteActionSuccessful: PropTypes.bool,
  favoritesError: PropTypes.string,
  setFavoriteName: PropTypes.string,
  suggestedFavoriteName: PropTypes.string,
  guestName: PropTypes.string,
  currentFavoriteNames: PropTypes.arrayOf(PropTypes.string),
};

FavoriteOrder.defaultProps = {
  isOpen: false,
  isFavorite: false,
  favoriteActionSuccessful: false,
  favoritesError: null,
  setFavoriteName: '',
  removeFromFavorites: () => {},
  updateFavoriteName: () => {},
  suggestedFavoriteName: '',
  guestName: '',
  currentFavoriteNames: [],
};

export default FavoriteOrder;
