import { Button } from '@cfa/react-components';
import PropTypes from 'prop-types';

const CreateInvoiceButton = ({ onPrint, children }) => (
  <Button
    onClick={onPrint}
    variant="outlined"
    color="secondary"
    fullWidth
  >
    {children}
  </Button>
);

CreateInvoiceButton.propTypes = {
  onPrint: PropTypes.func,
  children: PropTypes.string,
};

CreateInvoiceButton.defaultProps = {
  onPrint: () => {},
  children: '',
};

export default CreateInvoiceButton;
