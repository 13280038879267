import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { pathOr } from 'ramda';
import styled from 'styled-components';
import { Section } from '@cfacorp/cowponents';
import { Button } from '@cfa/react-components';
import { destroy } from 'redux-form';
import PropTypes from 'prop-types';
import { Icon, FavoriteOrder, Tooltip } from '../components';
import { formatLongDate, formatPrice } from '../util/format';
import {
  actions,
  selectCurrentFavoriteNames,
  selectFavoriteActionSuccessful,
  selectFavoritesError,
  selectFavoritesLoading,
  selectFavoriteSuggestedName,
} from '../reducers/guest';
import constants from '../constants';
import { selectTotalAmount, selectTransactionId } from '../reducers/order';
import { notifyBugsnag } from '../services/bugsnag';

export const Submit = props => {
  const {
    className,
    location,
    history,
    addToFavorites,
    favoritesLoading,
    favoritesError,
    addToFavoritesSuccessful,
    suggestedFavoriteName,
    currentFavoriteNames,
    transactionId,
    destroy: destroyForm,
    clearGuestState,
    resetFavoriteActionErrors,
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  let sentBugsnagNotify = false;
  const { state: locationState } = location;
  const { order, guestName, isCfaOneGuest } = locationState;
  const editOrder = pathOr('', ['error', 'editOrder'], order);
  useEffect(() => {
    destroyForm(
      constants.GET_FORM_TYPES.GUEST,
      constants.GET_FORM_TYPES.DETAILS,
      constants.GET_FORM_TYPES.PAYMENT_METHOD,
      constants.GET_FORM_TYPES.SECONDARY_CONTACT,
    );
  }, []);

  useEffect(() => () => {
    clearGuestState();
  }, []);

  const openModal = () => setModalIsOpen(true);

  const closeModal = ({ favoriteActionSuccessful }) => {
    setModalIsOpen(false);
    setIsFavorite(favoriteActionSuccessful);
    resetFavoriteActionErrors();
  };

  if (!locationState) {
    history.push('/');
    return null;
  }

  // on rare occasions we have seen order not exist in the incoming state
  // which caused exception/crash (consequences unknown)
  // to better diagnose this, we submit empty data for display and notify bugsnag
  const {
    subTotalAmount,
    taxAmount,
    destination,
    promiseDateTime,
    id,
    totalAmount,
  } = order ?? {
    subTotalAmount: 0,
    taxAmount: 0,
    destination: '',
    promiseDateTime: '1970-01-01T00:00:00',
    id: '',
    totalAmount: 0,
  };

  if (!order && !sentBugsnagNotify) {
    notifyBugsnag('Missing order details on Submit view', {
      context: 'Submit View Missing Order Details',
      info: {
        order,
      },
    });
    sentBugsnagNotify = true;
  }

  return (
    <div className={className}>
      <Section>
        <Icon>celebrate</Icon>
        <h3>{`Order ${editOrder ? 'Updated' : 'Placed'} Successfully`}</h3>
      </Section>
      <Section className="summary">
        <p>
          {`${destination} Order #`}
          <span data-cy="order-id">{id}</span>
          {` for ${guestName}`}
        </p>
        <p data-cy="promise-date">{`Promise date: ${formatLongDate(promiseDateTime)}`}</p>
        <p data-cy="final-total">{`Total: ${totalAmount !== 0 ? formatPrice(totalAmount) : formatPrice(subTotalAmount + taxAmount)}`}</p>
        {transactionId && (
          <Tooltip
            className="transId-tooltip"
            tooltipContent={(
              <div>
                <span className="title">Transaction ID:</span>
                <span> Year + Month + Day + Store Num + Transaction Number</span>
              </div>
            )}
          >
            <p data-cy="trans-id">{`TransactionID: ${transactionId}`}</p>
          </Tooltip>
        )}
      </Section>
      <Section>
        {isFavorite && (
          <>
            <Icon width="20px" height="20px" margin="5px">favorite</Icon>
            <div className="favorite-message">{`This order has been added to ${guestName}'s Favorites`}</div>
          </>
        )}
        <Button
          onClick={() => { history.push('/'); }}
        >
          Start New Order
        </Button>
        {
          isCfaOneGuest
          && !isFavorite
          && id
          && (
            <Button
              className="button-is-favorite"
              onClick={openModal}
            >
              Add to Favorites
            </Button>
          )
        }
      </Section>
      {modalIsOpen
        && isCfaOneGuest
        && id
        && (
          <FavoriteOrder
            close={closeModal}
            isOpen={modalIsOpen}
            isFavorite={isFavorite}
            guestName={guestName}
            addToFavorites={(favoriteName) => addToFavorites(id, favoriteName)}
            favoritesLoading={favoritesLoading}
            favoritesError={favoritesError}
            favoriteActionSuccessful={addToFavoritesSuccessful}
            suggestedFavoriteName={suggestedFavoriteName}
            currentFavoriteNames={currentFavoriteNames}
          />
        )}
    </div>
  );
};

export const StyledSubmit = styled(Submit)`
  color: ${(props) => props.theme.primary};
  text-align: center;
  & svg {
    fill: ${(props) => props.theme.primary};
  }
  & .summary p {
    margin: 2px;
    font-size: 14px;
    font-weight: bold;
    box-sizing: initial;
  }
  & .favorite-message {
    margin: 0 0 10px;
  }
  & .tooltip {
    top: 22px !important;
    left: unset !important;
    max-width: 80vw;
    .title {
      font-weight: bold;
    }
  }
`;

Submit.propTypes = {
  className: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])).isRequired,
  history: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.func,
  ])).isRequired,
  destroy: PropTypes.func.isRequired,
  addToFavorites: PropTypes.func.isRequired,
  resetFavoriteActionErrors: PropTypes.func.isRequired,
  favoritesLoading: PropTypes.bool,
  favoritesError: PropTypes.string,
  addToFavoritesSuccessful: PropTypes.bool,
  clearGuestState: PropTypes.func.isRequired,
  suggestedFavoriteName: PropTypes.string,
  currentFavoriteNames: PropTypes.arrayOf(PropTypes.string),
  transactionId: PropTypes.string,
};

Submit.defaultProps = {
  favoritesLoading: false,
  favoritesError: null,
  addToFavoritesSuccessful: false,
  suggestedFavoriteName: '',
  currentFavoriteNames: [],
  transactionId: '',
};

function mapStateToProps(state) {
  return {
    favoritesLoading: selectFavoritesLoading(state),
    favoritesError: selectFavoritesError(state),
    addToFavoritesSuccessful: selectFavoriteActionSuccessful(state),
    suggestedFavoriteName: selectFavoriteSuggestedName(state),
    currentFavoriteNames: selectCurrentFavoriteNames(state),
    transactionId: selectTransactionId(state),
    totalAmountFromApi: selectTotalAmount(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ destroy, ...actions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StyledSubmit);
