import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Flex, Input, Spinner,
} from '@cfacorp/cowponents';
import { Button } from '@cfa/react-components';
import styled from 'styled-components/macro';
import {
  Icon,
} from '../index';
import Success from '../../assets/success.svg';
import Error from '../../assets/error.svg';
import { isExpired } from '../../util/validate';
import ooeConstants from '../../constants';

export const VaultedCards = props => {
  const {
      cardSelected,
      validateZipSuccess,
      validateZip,
      zipLoading,
      vaultedCards,
      cardsLoading,
  } = props;
  const [cardToValidate, setCardToValidate] = useState(null);
  const [zip, setZip] = useState('');

  useEffect(() => {
    setCardToValidate((cardSelected && cardSelected.id) ? cardSelected.generatedId : null);
  }, [cardSelected]);

  const toggleValidateMode = (e, card) => {
    e.preventDefault();
    if (card.validated) validateZipSuccess([card]);
    setCardToValidate(card.generatedId);
    setZip('');
  };

  const callValidateZip = (event, card) => {
    const enteredZip = event.target.value;
    setZip(enteredZip);
    if (enteredZip.length >= 5) validateZip(enteredZip, card);
  };

  const renderZipValidation = card => {
    if (cardToValidate === card.generatedId || cardSelected.generatedId === card.generatedId) {
      if (zipLoading) {
        return (<Spinner className="spinner" />);
      }
      if (card.validated) {
        return (<img className="success" src={Success} alt="Success" />);
      }
      return (
        <>
          <Input
            height="37px"
            width="75px"
            m="0.5em"
            value={zip}
            autoFocus
            placeholder="Zip"
            data-cy="card-zip"
            onChange={(e) => callValidateZip(e, card)}
          />
          {cardSelected === 'error' && <img src={Error} alt="Error" className="error" />}
        </>
      );
    }
    if (isExpired(card)) {
      return <div>Card Expired</div>;
    }
    return (
      <Button
        color="secondary"
        onClick={(e) => toggleValidateMode(e, card)}
        style={{ minWidth: 'unset' }}
      >
        Select
      </Button>
    );
  };

  return (
    <StyledVaultedCards>
      {cardsLoading
        ? (
          <Flex>
            <Spinner className="card-spinner" />
          </Flex>
        )
        : vaultedCards?.map?.((card) => (
          <Flex key={card.generatedId} alignItems="center" className={isExpired(card) && 'expired'}>
            <Box width={1 / 6}>
              <Icon>{card.cardType?.toLowerCase?.()}</Icon>
            </Box>
            <Flex width={1 / 3} alignItems="center">
              {card.cardType !== ooeConstants.paypal
              && (
                <>
                  <span className="card-number">
                    &#9679;&#9679;&#9679;&#9679; &#9679;&#9679;&#9679;&#9679;&nbsp;
                  </span>
                  &#9679;&#9679;&#9679;&#9679;
                </>
              )}
              {card.accountDisplay}
            </Flex>
            <Box width={5 / 12} className="zip-container">
              {renderZipValidation(card)}
            </Box>
          </Flex>
        ))}
    </StyledVaultedCards>
  );
};

const StyledVaultedCards = styled.div`
  clear: both;

  & .zip-container {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
  }

  & .spinner {
    text-align: right !important;
  }
  & .success {
    width: 28px;
    margin-right: 30px;
  }
  & .error {
    width: 23px;
    position: absolute;
    right: -25px;
    top: 14px;
  }
  & .expired {
    opacity: 0.3;
    pointer-events: none;
  }

  @media(max-width: ${(props) => props.theme.phone}) {
    & .card-number {
      display: none;
    }
  }
`;

VaultedCards.propTypes = {
  vaultedCards: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])),
  cardsLoading: PropTypes.bool,
  validateZip: PropTypes.func.isRequired,
  zipLoading: PropTypes.bool.isRequired,
  cardSelected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
  validateZipSuccess: PropTypes.func.isRequired,
};

VaultedCards.defaultProps = {
  vaultedCards: [],
  cardsLoading: false,
};

export default VaultedCards;
