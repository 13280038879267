import { Button } from '@cfa/react-components';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Icon } from '../index';

const DashboardActionButton = (props) => {
  const {
    icon,
    onClick,
    buttonText,
    disabled,
  } = props;
  return (
    <StyledDashboardActionButton>
      <Button
        className={disabled ? 'action-button disabled' : 'action-button'}
        variant="text"
        size="md"
        color="secondary"
        disabled={disabled}
        onClick={disabled ? () => {} : onClick}
      >
        <Icon height="35px" width="35px">{icon}</Icon>
        <div>{buttonText}</div>
      </Button>
    </StyledDashboardActionButton>
  );
};

const StyledDashboardActionButton = styled.div`
  & .action-button {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: ${(props) => props.theme.primary};
    font: ${(props) => props.theme.smallBoldFont};
    margin: 20px;
    padding: 1px 7px 2px 7px;
    background: ${(props) => props.theme.background};
    min-width: unset;
  }

  & .action-button svg {
    margin: 0;
    min-height: 35px;
    fill: ${(props) => props.theme.primary};
  } 
  
  & .action-button:hover {
    cursor: pointer;
  }

  & .action-button:focus {
    outline: none;
  }

  :hover {
    background: #00000033;

    .action-button {
      background: fixed;
    }
  }

  & .disabled, .disabled svg {
    color: ${(props) => props.theme.outline} !important;
    fill: ${(props) => props.theme.outline};
    border: none;
    cursor: not-allowed;
  }
`;

DashboardActionButton.propTypes = {
  icon: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

DashboardActionButton.defaultProps = {
  disabled: false,
  onClick: () => {},
};

export default DashboardActionButton;
