import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Button } from '@cfa/react-components';

const WorkingHours = props => {
  const {
    restaurantWorkingHours,
    cateringDeliveryWorkingHours,
  } = props;

  const [isRestaurantHours, setIsRestaurantHours] = useState(true);

  const renderRestaurantHours = (
    restaurantWorkingHours.map(h => (
      <p key={h}>{h}</p>
    ))
  );

  const renderDeliveryHours = (
    cateringDeliveryWorkingHours.map(h => (
      <p key={h}>{h}</p>
    ))
  );

  const onClickRestaurantHandler = () => {
    setIsRestaurantHours(true);
  };

  const onClickDeliveryHandler = () => {
    setIsRestaurantHours(false);
  };
  return (
    <StyledWorkingHours>
      <div className="buttons-box">
        <Button variant="text" color="tertiary" size="sm" onClick={onClickRestaurantHandler} className={isRestaurantHours ? 'selected' : ''}>Restaurant Hours</Button>
        <Button variant="text" color="tertiary" size="sm" onClick={onClickDeliveryHandler} disabled={cateringDeliveryWorkingHours.length === 0} className={isRestaurantHours ? '' : 'selected'}>Delivery Hours</Button>
      </div>
      <div className="hours-box">
        <div style={isRestaurantHours ? {} : { display: 'none' }}>{renderRestaurantHours}</div>
        <div style={isRestaurantHours ? { display: 'none' } : {}}>{renderDeliveryHours}</div>
      </div>
    </StyledWorkingHours>
  );
};

const StyledWorkingHours = styled('div')`
  margin-bottom: 10px;
  & Button {
    width: 50%;
    min-width: unset;
    padding: 4px;
    border: 1px solid ${prop => prop.theme.primary};
    border-bottom: 0;
    border-radius: 0;
    &.selected {
      background-color: ${prop => prop.theme.primary};
      color: ${prop => prop.theme.secondary};
    }
    :disabled {
      border-bottom: 0;
      cursor: no-drop;
    }
  }
  & .hours-box {
    padding: 5px;
    border: solid 1px ${prop => prop.theme.text}
  }
`;

WorkingHours.propTypes = {
  restaurantWorkingHours: PropTypes.arrayOf(PropTypes.string),
  cateringDeliveryWorkingHours: PropTypes.arrayOf(PropTypes.string),
};

WorkingHours.defaultProps = {
  restaurantWorkingHours: [],
  cateringDeliveryWorkingHours: [],
};

export default WorkingHours;
