/* eslint-disable react/forbid-prop-types */
import { useState } from 'react';
import { groupBy, contains } from 'ramda';
import PropTypes from 'prop-types';
import {
  Box, Select, TextArea,
} from '@cfacorp/cowponents';
import { Button } from '@cfa/react-components';
import styled from 'styled-components/macro';
import {
  StyledMenuItem, Disclaimer, PromoFreeToggle,
} from '../index';
import ooeConstants from '../../constants';
import { debounce } from '../../util/utils';
import { notifyBugsnag } from '../../services/bugsnag';

function toggleable(mod) {
  const { tag } = mod;
  return contains(tag, ooeConstants.TOGGLEABLE_ITEM_TAGS);
}

function sauceDisclaimer(freeSauces, quantity) {
  if (freeSauces) {
    return (
      <Disclaimer className="sauce-disclaimer">
        {`${freeSauces * quantity} Free Sauce(s)`}
      </Disclaimer>
    );
  }
  return null;
}

function EditCartItem({
  itemTag,
  freeSauces,
  specialInstructions,
  editableItems,
  deleteItem,
  addModifier,
  updateSpecialInstructions,
  updateModifierQuantity,
  quantity,
  sideItems,
  updateSideItem,
  selectedSide,
  dessertItems,
  updateDessertItem,
  selectedDessert,
  cartItem,
  addToCart,
  id,
  toggleEditMode,
  makePromoFree,
  removePromoFree,
  isPromoFree,
  isVca,
}) {
  const [isToggledPromoFree, setTogglePromoFree] = useState(isPromoFree);
  const update = debounce((text) => {
    updateSpecialInstructions(text);
  }, 300);
  const sidesObj = groupBy((sideItem) => sideItem.tag)(sideItems);
  const dessertsObj = groupBy((dessertItem) => dessertItem.tag)(dessertItems);

  const togglePromoFreeHandler = e => {
    if (e.target.checked === true) {
      setTogglePromoFree(!isToggledPromoFree);
      makePromoFree(id);
    } else {
      setTogglePromoFree(!isToggledPromoFree);
      removePromoFree(id);
    }
  };

  const handleSideItemChange = (event, sides, item) => {
    try {
      updateSideItem(id, sides[event.target.value][0], item);
    } catch (error) {
      notifyBugsnag('Edit Side Item Failed', {
        context: 'Edit Cart Item',
        info: {
          error,
          sides,
          item,
          eventTargetValue: event?.target?.value ?? 'undefined or null',
        },
      });
    }
  };

  const handleDessertItemChange = (event, desserts, item) => {
    try {
      updateDessertItem(id, desserts[event.target.value][0], item);
    } catch (error) {
      notifyBugsnag('Edit Dessert Item Failed', {
        context: 'Edit Cart Item',
        info: {
          error,
          desserts,
          item,
          eventTargetValue: event?.target?.value ?? 'undefined or null',
        },
      });
    }
  };

  const handleAddAnotherClicked = () => {
    addToCart(cartItem, true);
    toggleEditMode();
  };

  return (
    <StyledEditCartItem
      data-testid="cart-item-edit-box"
    >
      {sauceDisclaimer(freeSauces, quantity)}
      <div className="mods">
        {editableItems.filter((subItem) => subItem.tag !== 'CARAMEL').map((mod) => (
          <StyledMenuItem
            className="menu-item"
            item={mod}
            addToCart={(e) => addModifier(id, e, { comboTag: mod.comboTag })}
            updateQuantity={(qty) => updateModifierQuantity(
              id,
              mod,
              qty,
              { comboTag: mod.comboTag },
            )}
            quantity={mod.quantity}
            key={mod.tag}
            imageSize="sm"
            hidePricing
            toggleable={toggleable(mod)}
            recipe={mod.modifierType === 'RECIPE'}
          />
        ))}
      </div>
      <Box>
        {sideItems.length > 0
        && (
        <Select
          onChange={(e) => handleSideItemChange(e, sidesObj, itemTag)}
          value={selectedSide.tag}
          data-cy={`${itemTag}-side`}
          width="calc(100% - 20px)"
        >
          { sideItems.map((side) => (
            <option
              key={side.tag}
              value={side.tag}
              data-value={side}
            >
              {side.name}
            </option>
          ))}
        </Select>
        )}
      </Box>
      <Box>
        {dessertItems.length > 0
        && (
        <Select
          onChange={(e) => handleDessertItemChange(e, dessertsObj, itemTag)}
          value={selectedDessert.tag}
          data-cy={`${itemTag}-side`}
          width="calc(100% - 20px)"
        >
          { dessertItems.map((dessert) => (
            <option
              key={dessert.tag}
              value={dessert.tag}
              data-value={dessert}
            >
              {dessert.name}
            </option>
          ))}
        </Select>
        )}
      </Box>
      {!isVca && (
        <PromoFreeToggle id={id} isToggledPromoFree={isToggledPromoFree} mr="10px" togglePromoFreeHandler={togglePromoFreeHandler} />
      )}
      <TextArea
        className="special-instructions"
        defaultValue={specialInstructions}
        placeholder="Special Instructions"
        onChange={(e) => update(e.target.value)}
        maxLength={40}
      />
      <ButtonContainer>
        <Button
          className="add-another"
          onClick={handleAddAnotherClicked}
          variant="outlined"
          color="secondary"
          fullWidth
          data-cy={`split-${itemTag}`}
        >
          Add Another & Customize
        </Button>
        <Button
          className="delete-item"
          variant="destructive"
          onClick={deleteItem}
          fullWidth
          data-cy={`delete-${itemTag}`}
        >
          Delete Item
        </Button>
      </ButtonContainer>

    </StyledEditCartItem>
  );
}

const StyledEditCartItem = styled.div`
  margin-top: 20px;
  & .mods {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  & .sauce-info {
    width: 100%;
    text-align: left;
    margin: 5px 12px;
    font-size: 12px;
    font-weight: bold;
    color: ${(props) => props.theme.primary};
  }

  & .sauce-disclaimer {
    text-align: center;
    margin-bottom: 15px;
  }

  & .special-instructions {
    height: 60px;
  }
  & .promo-free-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  
  @media (max-width: ${(props) => props.theme.phone}) {
    flex-direction: column;
  }
  
  & button {
    margin: 8px;
  }
`;

EditCartItem.propTypes = {
  freeSauces: PropTypes.number,
  itemTag: PropTypes.string.isRequired,
  specialInstructions: PropTypes.string,
  editableItems: PropTypes.arrayOf(PropTypes.object),
  deleteItem: PropTypes.func,
  addModifier: PropTypes.func,
  addToCart: PropTypes.func,
  toggleEditMode: PropTypes.func,
  updateSpecialInstructions: PropTypes.func,
  updateModifierQuantity: PropTypes.func,
  quantity: PropTypes.number,
  id: PropTypes.string,
  sideItems: PropTypes.arrayOf(PropTypes.object),
  selectedSide: PropTypes.objectOf(PropTypes.any),
  dessertItems: PropTypes.arrayOf(PropTypes.object),
  selectedDessert: PropTypes.objectOf(PropTypes.any),
  cartItem: PropTypes.objectOf(PropTypes.any),
  updateSideItem: PropTypes.func.isRequired,
  updateDessertItem: PropTypes.func.isRequired,
  makePromoFree: PropTypes.func,
  removePromoFree: PropTypes.func,
  isPromoFree: PropTypes.bool,
  isVca: PropTypes.bool,
};

EditCartItem.defaultProps = {
  cartItem: {},
  editableItems: [],
  specialInstructions: '',
  deleteItem: () => {},
  addModifier: () => {},
  addToCart: () => {},
  updateModifierQuantity: () => {},
  updateSpecialInstructions: () => {},
  toggleEditMode: () => {},
  quantity: 0,
  selectedSide: {},
  sideItems: [],
  selectedDessert: {},
  dessertItems: [],
  freeSauces: 0,
  id: '0',
  isPromoFree: false,
  makePromoFree: () => {},
  removePromoFree: () => {},
  isVca: false,
};

export default EditCartItem;
